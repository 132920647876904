import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Auth/Login';
import Registration from './Auth/Registration'
import Verification from './Auth/Verification'
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Myvideos from './Pages/Evideo/MyVideos/Myvideos';
import Evideo from './Pages/Evideo/CreateVideos/Excelvideo';
import Trashed from './Pages/Evideo/MyVideos/Trashvideos '
import Cvideo from './Pages/Evideo/CreateVideos/Createvideo';
import Addteam from './Pages/Team/Addteam';
import GetTeamMembers from './Pages/Team/Team';
import EditTeam from './Pages/Team/Editteam';
import Reports from './Pages/Reports/Reports';
import AdminReports from './Pages/Reports/AdminReports';
import TeamMemberReports from './Pages/Reports/TeamMemberReports';

function App() {
    function AuthCheck(){
		if(localStorage.getItem("user")){
			 return true
		}else{
			 return false
		}
	}
    return (
        <>
            <BrowserRouter  basename={'/'} >
                    <Routes >
						<Route exact path="/login" element={<Login />} />
                        <Route exact path="/registration" element={<Registration />} />
                        <Route exact path="/verification" element={<Verification />} />
                        <Route exact path="/myvideos" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Myvideos /></PrivateRoute>} />
                        <Route exact path="/createvideos" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Evideo /></PrivateRoute>} />
                        <Route exact path="/createvideo" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Cvideo/></PrivateRoute>} />
                        <Route exact path="/trashed" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Trashed /></PrivateRoute>} />
                        <Route exact path="/addteam" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Addteam /></PrivateRoute>} />
                        <Route exact path="/getteam-members/" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <GetTeamMembers /></PrivateRoute>} />
                        <Route exact path="/team/edit/:UserId" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <EditTeam /></PrivateRoute>} />
                        <Route exact path="/reports" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <Reports/></PrivateRoute>} />
                        <Route exact path="/adminreports/:id" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <AdminReports /></PrivateRoute>} />
                        <Route exact path="/teammemberreports/:id" element={<PrivateRoute currentUser={AuthCheck()} redirectTo='/login' > <TeamMemberReports/></PrivateRoute>} />
                    </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
