import React from 'react';

export default function () {

	function getAuthtoken() {
		var userData = localStorage.getItem('user');
		if (!userData) {
		} else {
			var u = JSON.parse(userData)
			var token = u.token
			return token
		}
	}
	return getAuthtoken()
}
