import React from 'react';
// import { MdOutlineDoubleArrow } from "react-icons/md";
import Loader from '../../src/loader/loader.gif'
export default function PermLoader() {

    return (
        <>
            <div className='permloading'>
                <img src={Loader}/>
            </div>
        </>
    )
}
