import config from "../config";
import Authtoken from './GetAuthToken';
export default async function PermissionGet(permissionid) {
    let token = Authtoken()
    var newFromdata = new FormData();
    newFromdata.append('token', token);
    newFromdata.append('permissionid', permissionid);
    let url = config.API_BASE_URL + "permissions/checkpermissions.php"
    return   fetch(url, {
            method: 'POST',
            body: newFromdata
        }).then(response => {
            if(response.status == 403){
                localStorage.removeItem('user');
                window.location.href = config.Login_Url
            }
            return response.json();  // <---- this is important
        }).then(response => {
            if(response.status == 200){
                return true
            }else{
                return false;
            }
        });














    // let url = config.API_BASE_URL + "permissions/checkpermissions.php"
    // return fetch(url, { 
    //     method: 'POST',
    //     body: newFromdata
    // }).then(response => {
    //     if(response.status == 403){
    //         localStorage.removeItem('user');
    //         window.location.href = config.Login_Url
    //     }
    // }).then(response => {
    //     if(response.status == '200'){
    //         return true
    //     }else{
    //         return false;
    //     }
    // })
}