import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import { Link } from "react-router-dom";
import { FaUser,FaUserPlus } from "react-icons/fa";
import '../Auth/Registration.css';
import Logo from '../Utils/logo.png'
import { useSearchParams } from 'react-router-dom';
import config from '../config'

export default function Login() {
    const [verification, setVerification] = useState('');
    const [errmsg, seterrmsg] = useState(false);
    const [searchParams] = useSearchParams();
    console.log(searchParams)
    const Email = (searchParams.get('email')) ? searchParams.get('email') : ''

    function getVerification(e) {
        setVerification(e.target.value)
        seterrmsg(false)
    }
    function validateverification(e) {
        e.preventDefault();
        seterrmsg(false)
        if (!verification) {
            seterrmsg("OTP is required")
            return false
        }else{
            var newFromdata = new FormData();
            newFromdata.append('verification', verification);
            newFromdata.append('email', Email);
            let url = config.API_BASE_URL + "register/verifyotp.php"
            fetch(url, {
                method: 'POST',
                body: newFromdata
            }).then(response => {
                return response.json();  // <---- this is important
            }).then(response => {
                if (response.CODE == 200) {
                    window.location.href = config.Login_Url;
                }
                if (response.CODE != 200) {
                    seterrmsg(response.errmsg)
                }
            });
        }
    }
    return(
        <>
        <div className='wrapper '>
                <div className='navbar '>
                    <div className='navbarinner container'>
                        <span>
                        <Link to="/login">
                            <img className='logo' src={Logo} />
                        </Link>
                        </span>
                        <div className='navrightsec'>
                        </div>
                    </div>
                </div>
                <div className='form-wrap-verf'>
                    <div className='row-form'>
                    <span className='user'>
                            <FaUser className='loguser' />
                        </span>
                    <div className='welcome-text'>
                            <h2>OTP Verification</h2>
                        </div>
                        <div className="err-container">
                            {(errmsg) ? <p className="errp">{errmsg}</p>
                                : ''
                            }

                        </div>
                        <div className='form-style'>
                            <form>
                            <div className="form-group form-verification">
                                    <input type="text" placeholder=" Enter OTP here" className="form-control form-ok" required onChange={(e) => getVerification(e)} value={verification}/>

                                </div>
                                <div className="pb-3 btn-imp">
                                    <button type="submit" className="btn w-100 ew-primary-col ew-primary-col-text mt-2 btn-form btn-log" onClick={(e) => validateverification(e)}>Submit</button>
                                </div>
                            </form>
                            </div>
                    </div>
                    </div>
                </div>
        <Footer/>
        </>
    )
}