import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import { Link } from "react-router-dom";
import { FaUser,FaUserPlus } from "react-icons/fa";
import '../Auth/Registration.css'
import Authtoken from  '../permissions/GetAuthToken'
import config from '../config'

export default function Login() {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [errmsg, seterrmsg] = useState(false);
    const [sucessmsg,setsucessmsg]=useState(false);
    function getFirstname(e) {
        setFirstName(e.target.value)
        seterrmsg(false)
    }
    function getLastname(e) {
        setLastName(e.target.value)
        seterrmsg(false)
    }
    function getEmail(e) {
        setEmail(e.target.value)
        seterrmsg(false)
    }
    function getUsername(e) {
        setUsername(e.target.value)
        seterrmsg(false)
    }
    function getPassword(e) {
        setPassword(e.target.value)
        seterrmsg(false)
    }
    function validateregistration(e) {
        e.preventDefault();
        seterrmsg(false)
        setsucessmsg(false)
        if (!FirstName) {
            seterrmsg("First name is required")
            return false
        } else if (!LastName) {
            seterrmsg("Last name is required")
        } else if (!Email) {
            seterrmsg("Email address is required")
        } else if (!Username) {
            seterrmsg("Username is required")
        }else if (!Password) {
            seterrmsg("Password is required")
        }else{
            var newFromdata = new FormData();
            newFromdata.append('firstname', FirstName);
            newFromdata.append('lastname', LastName);
            newFromdata.append('email', Email);
            newFromdata.append('username', Username);
            newFromdata.append('password', Password);
            newFromdata.append('token', Authtoken());
            let url = config.API_BASE_URL + "register/register.php"
            fetch(url, {
                method: 'POST',
                body: newFromdata
            }).then(response => {
                return response.json();  // <---- this is important
            }).then(response => {
                
                if (response.CODE == 200) {
                    window.location.href = config.baseurl + "verification/?email=email";
                }
                if (response.CODE != 200) {
                    seterrmsg(response.errmsg)
                }
            });
        }
    }
    return (
        <>
            <div className='wrapper '>
                <div className='navbar '>
                    <div className='navbarinner container'>
                        <span>
                            <Link to="/registration">
                                <img className='logo' src='./logo.png' />
                            </Link>
                        </span>
                        <div className='navrightsec'>
                        </div>
                    </div>
                </div>
                <div className='form-wrap'>
                    <div className='row-form'>
                        <span className='user'>
                            <FaUser className='loguser' />
                        </span>
                        <div className='welcome-text'>
                            <h2>Sign Up</h2>
                        </div>
                        <div className="err-container">
                            {(errmsg) ? <p className="errp">{errmsg}</p>
                                : ''
                            }

                        </div>
                        <div className="succ-container">
                            {(sucessmsg) ? <p className="succp">{sucessmsg}</p>
                                : ''
                            }

                        </div>
                        <div className='form-style'>
                            <form>
                                <div className="form-group form-registration">
                                    <input type="text" placeholder="Customer First Name" className="form-control form-ok" required onChange={(e) => getFirstname(e)} value={FirstName} />

                                </div>
                                <div className="form-group form-registration">
                                    <input type="text" placeholder="Customer Last Name" className="form-control form-ok" required onChange={(e) => getLastname(e)} value={LastName} />

                                </div>
                                <div className="form-group form-registration">
                                    <input type="email" placeholder="Customer E-mail Address" className="form-control form-ok" required onChange={(e) => getEmail(e)} value={Email} />

                                </div>
                                <div className="form-group form-registration">
                                    <input type="text" placeholder="Customer Username" className="form-control form-ok" required onChange={(e) => getUsername(e)} value={Username} />

                                </div>
                                <div className="form-group form-registration">
                                    <input type="password" placeholder="Customer Password" className="form-control form-ok" required onChange={(e) => getPassword(e)} value={Password} />

                                </div>
                                <div className="pb-3 btn-imp">
                                    <button type="submit" className="btn w-100 ew-primary-col ew-primary-col-text mt-2 btn-form btn-log" onClick={(e) => validateregistration(e)}>Sign Up</button>
                                </div>
                            </form>
                        </div>
                    </div>  
                </div>
                <Footer/>
            </div>
        </>
    )
}