import React, { useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import config from '../../config';
import { FaPencilAlt } from "react-icons/fa";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { IconContext } from "react-icons";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import '../Customer/Customer.css'
import Authtoken from '../../permissions/GetAuthToken';
import './Team.css'
import GetPermission from '../../permissions/PermissionGet';
import PermLoader from '../../permissions/PermLoader';

export default function Customer() {

  const [TeamMembers, setTeamMembers] = useState([])
  const [gridApi, setGridApi] = useState(null);
  const [CanEditTeam, setCanEditTeam] = useState(false);
  const [CanView, setCanView] = useState(false);
  const [PermissionLoader, setPermissionLoader] = useState(true);




  useEffect(() => {
    async function getpermissions() {
      await GetPermission(process.env.REACT_APP_Edit_Team_Member).then(result => {
        if (result) {
          setCanEditTeam(true)
        }
      });
      await GetPermission(process.env.REACT_APP_View_Team_Member).then(result => {
        if (result) {
          setCanView(true)
        }
      });
      setPermissionLoader(false)
    }
    getpermissions()
  }, []);


  useEffect(() => {
    getTeamMembers()
  }, [CanView]);

  const onGridReady = (params) => {
    setGridApi(params.api)
  }

  function getTeamMembers() {
    if (CanView) {
      var url = config.API_BASE_URL + 'teams/getteam.php'
      var newFromdata = new FormData();
      newFromdata.append('token', Authtoken());
      fetch(url, {
        method: 'POST',
        headers: {
          // authorization: Authtoken()
        },
        body: newFromdata
      }).then(response => {
        return response.json();  // <---- this is important
      }).then(response => {
        if (response.CODE == 200) {
          setTeamMembers(response.data)
        }

      });
    }

  }


  const [columnDefs] = useState([
    {
      headerName: '',
      cellClass: ["ag-cell-pencileditico"],
      cellRenderer: (data) => {
        return <>
          <a href={`${config.baseurl}team/edit/${data.data.CustomerId}`} className="View_btn">
            <IconContext.Provider
              value={{ color: 'gray' }}
            >
              <div className='userpencilicon'>
                <FaPencilAlt />
              </div>
            </IconContext.Provider>
          </a>
        </>

      },
      colId: 'action',
      editable: false,
      width: 50,
      resizable: true
    },
    { field: 'CustomerName', headerName: 'Name', sortable: true, filter: true, resizable: true, },
    { field: 'CustomerEmail', headerName: 'Email', sortable: true, filter: true, resizable: true, },
    { field: 'CustomerUsername', headerName: 'User name', sortable: true, filter: true, resizable: true, },
  ])

  return (
    <>

      <div className='customcont'>
        <Header />
        {(PermissionLoader) ?
          <PermLoader />
          :
          <>
            {(!CanView) ?
              <p className='unautmessage'>Your are not authorized to view this page.</p>
              :
              <>
                <div className='headingsec'>
                  <div className='headingsecinner'>
                    <h2>Team Members</h2>
                    <button><a href='./addteam' className='team anchor'>Add team member</a></button>
                  </div>
                </div>

                <div className="ag-theme-alpine" style={{ width: '100%', paddingLeft: 10, paddingRight: 10, height: '74vh' }}>
                  <AgGridReact
                    filter='text'
                    enableColResize={true}
                    animateRows={true}
                    showToolPanel={true}
                    enableSorting={true}
                    pagination={true}
                    onGridReady={onGridReady}
                    rowData={TeamMembers}
                    columnDefs={columnDefs}>
                  </AgGridReact>
                </div>
              </>
            }
          </>
        }

        <Footer />
      </div>
    </>
  )
}
