import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom";
import '../Header/Header.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import { FaUser } from "react-icons/fa";
import Logo from '../Utils/logo.png'
export default function Header() {

    const [Name, setName] = useState('');

    const history = useNavigate();
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        history("/login");
    };

    useEffect(() => {
        let mydata = localStorage.getItem('user');
        setName(JSON.parse(mydata).data[0][0].CustomerName)
    }, []);
   
    return (
        <>

            <div className='navbar '>
                <div className='navbarinner container'>
                    <span>
                        <Link to="/login">
                            <img className='logo' src={Logo} />
                        </Link>
                    </span>

                    <div className='dropdownswrap'>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Video
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><Link to='/createvideos'>Create Videos From Excel</Link></li>
                                <li><Link to='/CreateVideo'>Create Videos</Link></li>
                                <li><Link to='/myvideos'>My Videos</Link></li>
                                <li><Link to='/trashed'>Trashed</Link></li>
                            </ul>
                        </div>
                        <div className="addteammember">
                            <Link to='/getteam-members/'>Team</Link>
                        </div>
                        <div className="addteammember">
                            <Link to='/reports'>Reports</Link>
                        </div>

                        <div className="dropdown dropdown-user usernamear">
                            <div className="usernameid">
                                <h2>{Name}</h2>
                            </div>
                            <div className='useralt'>
                                <button className="btn btn-secondary dropdown-toggle " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FaUser />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={() => logout()}><a>Log out</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    {/* <div onClick={() => logout()}>
                        button
                    </div> */}
                </div>
            </div>

        </>
    )
}




