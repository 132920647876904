import React, { useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Link } from "react-router-dom";
import { FaUser, FaEnvelope, FaKey } from "react-icons/fa";
import showPwdImg from '../Utils/eye-cross.webp';
import hidePwdImg from '../Utils/eye-open.webp';
import '../Auth/Login.css'
import config from '../config';
export default function Login() {

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [UserName, setUserName] = useState('');
  const [Pasword, setPasword] = useState('');
  const [usernameErr, setusernameErr] = useState(0);
  const [userPassworderr, setuserPassworderr] = useState(0);
  const [userNotfounderr, setuserNotfounderr] = useState(0);
  const [loginerr, setloginerr] = useState(0);

  function getUsername(e) {
    setusernameErr(0)
    setuserNotfounderr(0)
    setloginerr(0)
    let username = e.target.value
    setUserName(username)
  }

  function getpassword(e) {
    setuserPassworderr(0)
    setuserNotfounderr(0)
    setloginerr(0)
    let password = e.target.value
    setPasword(password);
  }

  function login(e) {
    e.preventDefault()
    var username = UserName
    var pass = Pasword
    if (!username) {
      setusernameErr(1)
    } else if (!pass) {
      setuserPassworderr(1)
    } else {
      let url = config.API_BASE_URL+"login/login.php"
      fetch(url, {
        method: 'POST',
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({
          username: UserName, pass: Pasword
        })
      }).then((res) => res.json())
        .then((result) => {
          if (result.CODE == '200') {
            window.localStorage.setItem('user',JSON.stringify(result))
            window.location.href = config.baseurl + "createvideos";
          } else if (result.CODE == '404') {
            setuserNotfounderr(1)
          } else {
            setloginerr(1)
          }
        })
    }
  }

  return (
    <>
      <div className='wrapper '>
        <div className='navbar '>
                <div className='navbarinner container'>
                    <span>
                        <Link to="/login">
                            <img className='logo' src='./logo.png' />
                        </Link>
                    </span>
                    <div className='navrightsec'>
                    </div>
                </div>
            </div>

        <div className='form-wrap'>
          <div className='row-form'>
            <span className='user'>
              <FaUser className='loguser' />
            </span>
            <div className='welcome-text'>
              <h2>Welcome</h2>
            </div>
            <div className="err-container">
              {(usernameErr) ? <> <p className="errp">Username is required</p> </> : ''}
              {(userPassworderr) ? <> <p className="errp">Password is required</p> </> : ''}
              {(userNotfounderr) ? <> <p className="errp">Invalid Username Or Password</p> </> : ''}
              {(loginerr) ? <> <p className="errp">Oops something went wrong</p> </> : ''}
            </div>
            <div className='form-style'>
              <form>
                <div className="form-group form-log_in">
                  <div className="ew-svg ew-left">
                    <FaEnvelope className='logenvelope' />
                  </div>
                  <input type="email" placeholder="E-mail Address" className="form-control form-ok" required onChange={(e) => getUsername(e)} value={UserName} />

                </div>

                <div className="form-group form-log_in">
                  <div className="ew-svg ew-left">
                    <FaKey className='logkey' />
                  </div>
                  <input type={isRevealPwd ? "text" : "password"} placeholder="Password" className="form-control form-ok" onChange={(e) => getpassword(e)} value={Pasword} required />

                  <img
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                  />

                </div>

                <div className="d-flex align-items-center justify-content-between submit-form">
                  <div className="d-flex align-items-center log-remember"><input name="" type="checkbox" value="" /> <span className="pl-2 remeberlog">Remember Me</span></div>
                </div>
                <div className="pb-3 btn-imp">
                  <button type="submit" className="btn w-100 ew-primary-col ew-primary-col-text mt-2 btn-form btn-log" onClick={(e) => login(e)}  >Sign In</button>
                </div>
                <div className="navregister">
               <p> New User<span> <Link to="/registration"> Register Here </Link></span></p>   
                  </div>
              </form> 
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}
