import React, { useState, useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import './Addteam.css'
import Authtoken from '../../permissions/GetAuthToken';
import config from '../../config';
import { useParams } from "react-router-dom";
import GetPermission from '../../permissions/PermissionGet';
import PermLoader from '../../permissions/PermLoader';


export default function Addteam() {
    const [errmsg, seterrmsg] = useState(false);
    const [successmsg, setsuccessmsg] = useState('');
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [Permissions, setPermissions] = useState([])
    const [Teammember, setTeammember] = useState([])
    const [SelectedPermissionsarray, setSelectedPermissionsarray] = useState([])
    const [SelectedTeammembersarray, setSelectedTeammembersarray] = useState([])
    const paramas = useParams();
    const id = paramas.UserId
    const [CanEditTeam, setCanEditTeam] = useState(false);
    const [CanView, setCanView] = useState(false);
    const [PermissionLoader, setPermissionLoader] = useState(true);

    useEffect(() => {
        async function getpermissions() {
            await GetPermission(process.env.REACT_APP_Edit_Team_Member).then(result => {
                if (result) {
                    setCanEditTeam(true)
                }
            });
            await GetPermission(process.env.REACT_APP_View_Team_Member).then(result => {
                if (result) {
                    setCanView(true)
                }
            });
        }
        
        getpermissions()
    }, []);

    useEffect(() => {
        getuserDetails()
    }, [CanView])

    function getPermissions() {
        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        let url = config.API_BASE_URL + "permissions/getpermissions.php"
        fetch(url, {
            method: 'POST',
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            setPermissions(response.data)
        });
    }

    function getTeammembers() {
        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('cid', id);
        let url = config.API_BASE_URL + "permissions/getteammembers.php"
        fetch(url, {
            method: 'POST',
            // headers: {
            //     authorization: Authtoken(),
            // },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            setTeammember(response.data)
        });
    }
    function getuserDetails() {
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('id', id);
        let url = config.API_BASE_URL + "teams/editteam.php"
        fetch(url, {
            method: 'POST',
            // headers: {
            //     authorization: Authtoken(),
            // },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            
            setFirstname(response.teammember.firstname)
            setLastname(response.teammember.lastname)
            setEmail(response.teammember.email)
            setUsername(response.teammember.username)
            setSelectedPermissionsarray(response.teammemberperm)
            let teammeberdb = response.teammemberviewdownload
            if(teammeberdb.length > 0){
                 setSelectedTeammembersarray(teammeberdb[0].split(','))
            }
            getPermissions()
            getTeammembers()            
        });
    }

    function getfirstname(e) {
        setFirstname(e.target.value)
        seterrmsg(false)
    }
    function getlastname(e) {
        setLastname(e.target.value)
        seterrmsg(false)
    }
    function getemail(e) {
        setEmail(e.target.value)
        seterrmsg(false)
    }
    function getusername(e) {
        setUsername(e.target.value)
        seterrmsg(false)
    }
    function getpassword(e) {
        setPassword(e.target.value)
        seterrmsg(false)
    }

    function validateteam(e) {
        e.preventDefault();
        seterrmsg(false)
        setsuccessmsg(false)
        if (!firstname) {
            seterrmsg("First name is required")
            return false
        } else if (!lastname) {
            seterrmsg("Last name is required")
            return false
        } else if (!email) {
            seterrmsg("Email is required")
            return false
        } else if (!username) {
            seterrmsg("Username is required")
            return false
        } else if (SelectedPermissionsarray.length < 1) {
            seterrmsg('Permissions are required');
            return false;
        } else if (SelectedTeammembersarray.length < 1) {
            seterrmsg('Team Members are required');
            return false;
        } else {
            var newFromdata = new FormData();
            newFromdata.append('id', id);
            newFromdata.append('firstname', firstname);
            newFromdata.append('lastname', lastname);
            newFromdata.append('email', email);
            newFromdata.append('username', username);
            newFromdata.append('password', password);
            newFromdata.append('token', Authtoken());
            newFromdata.append('Permissions', SelectedPermissionsarray.join(','));
            newFromdata.append('Teammember', SelectedTeammembersarray.join(','));

            let url = config.API_BASE_URL + "teams/updateteam.php"
            fetch(url, {
                method: 'POST',
                body: newFromdata
            }).then(response => {
                return response.json();  // <---- this is important
            }).then(response => {
                if (response.CODE == 200) {
                    setsuccessmsg('Team member updated successfully')
                }
                if (response.CODE != 200) {
                    seterrmsg(response.errmsg)
                }
            });
        }

    }

    function getpermissionsvalue(e) {
        seterrmsg(false)
        setsuccessmsg(false)
        var checked = e.target.checked
        var rvalue = e.target.value
        var oldarray = SelectedPermissionsarray
        var newarray = oldarray.filter(item => item == rvalue).map(number => number);
        var newarray = oldarray.filter((rvalue) => {
            return rvalue !== -1;
        });

        if (checked) {
            newarray.push(rvalue)
            setSelectedPermissionsarray(newarray)
        } else {
            newarray.pop(rvalue)
            setSelectedPermissionsarray(newarray)
        }
    }

    function getTeammembersvalue(e) {
        seterrmsg(false)
        setsuccessmsg(false)
        var checked = e.target.checked
        var rvalue = e.target.value
        var oldarray = SelectedTeammembersarray
        var newarray = oldarray.filter(item => item == rvalue).map(number => number);
        var newarray = oldarray.filter((rvalue) => {
            return rvalue !== -1;
        });
        if (checked) {
            newarray.push(rvalue)
            setSelectedTeammembersarray(newarray)
        } else {
            newarray.pop(rvalue)
            setSelectedTeammembersarray(newarray)
        }
    }

    return (
        <>
            <div className='customcont'>
                <Header />
                <>
                    <div className='headingsec'>
                        <div className='headingsecinner'>
                            <h2>Edit Team Member</h2>
                            <div className='file'>
                                {CanEditTeam ?
                                    <button onClick={(e) => validateteam(e)}>Update</button>
                                    :
                                    ''
                                }

                            </div>
                            <div className="errordiv">
                                {(errmsg) ?
                                    <p className="errormsg">{errmsg}</p>
                                    : ''
                                }
                            </div>
                            <div className="succcessdiv">
                                {(successmsg) ?
                                    <p className="succmsg">{successmsg}</p>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className='v-container'>
                        <div className='v-containerinner'>
                            <div className="v-first-name">
                                <h2>First Name</h2>
                                <input type='text' className="form-control ewpInline2" name='firstname' onChange={(e) => getfirstname(e)} value={firstname} />
                            </div>
                            <div className="v-last-name">
                                <h2>Last Name</h2>
                                <input type='text' className="form-control ewpInline2" name='lastname' onChange={(e) => getlastname(e)} value={lastname} />
                            </div>
                            <div className="v-email">
                                <h2>Email Address</h2>
                                <input type='text' className="form-control ewpInline2" name='email' onChange={(e) => getemail(e)} value={email} />
                            </div>
                            <div className="v-username">
                                <h2>Username</h2>
                                <input type='text' className="form-control ewpInline2" name='username' onChange={(e) => getusername(e)} value={username} />
                            </div>
                            <div className="v-password">
                                <h2>Password</h2>
                                <input type='password' className="form-control ewpInline2" name='password' onChange={(e) => getpassword(e)} value={password} />
                            </div>

                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button accodcsbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Permission
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className='row selectaccordv'>
                                                {Permissions.map((item, ind) => {
                                                    if(SelectedPermissionsarray.includes(item.PermissionId.toString())){
                                                        return (
                                                            <div className='col-lg-3' key={ind} >
                                                                <input type="checkbox"
                                                                    defaultChecked={true}
                                                                    value={item.PermissionId} onChange={(e) => getpermissionsvalue(e)} />
                                                                <label className="selectlabel"> {item.PermissionName}</label>
                                                            </div>
                                                        )
                                                    } else{
                                                        return (
                                                            <div className='col-lg-3' key={ind} >
                                                                <input type="checkbox"
                                                                    defaultChecked={false}
                                                                    value={item.PermissionId} onChange={(e) => getpermissionsvalue(e)} />
                                                                <label className="selectlabel"> {item.PermissionName}</label>
                                                            </div>
                                                        )
                                                    }
                                                   
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button accodcsbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            View/Download
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className='row selectaccordv'>
                                                {Teammember.map((item, ind) => {
                                                    return (
                                                        <div className='col-lg-3' key={ind} >
                                                            <input type="checkbox"
                                                                defaultChecked={SelectedTeammembersarray.includes(item.CustomerId.toString())}
                                                                key={ind} value={item.CustomerId} onChange={(e) => getTeammembersvalue(e)} />
                                                            <label className="selectlabel"> {item.CustomerName}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <Footer />
            </div>
        </>

    )
}
