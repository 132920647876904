import React, { useState, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react';
import grid from 'ag-grid-react'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
import config from '../../../config'
import '../CreateVideos/Excelvideo.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Authtoken from '../../../permissions/GetAuthToken';
import { BsCalendar2Date, BsCheck2 } from "react-icons/bs";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import loadingtrash from '../../../loader/loader.gif'
import { IoIosPlayCircle } from "react-icons/io";
import GetPermission from '../../../permissions/PermissionGet';
import PermLoader from '../../../permissions/PermLoader';

export default function Myvideos() {

    const [gridApi, setGridApi] = useState(null);
    const [Videodata, setVideodata] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [StartDate, setStartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const [Selectedrow, setSelectedrow] = useState();
    const [SelectedViedeoId, setSelectedViedeoId] = useState();
    const [loader, setLoader] = useState(false);
    const [videopop, setvideopop] = useState('');
    const videoRef = useRef();

    const [Canview, setCanview] = useState(false);
    const [PermissionLoader, setPermissionLoader] = useState(true);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    ]);

    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    useEffect(() => {
        async function getpermissions(){
            await GetPermission( process.env.REACT_APP_create_View_Video).then(result => {
                if(result){
                    setCanview(true)
                }
            });
            setPermissionLoader(false)
        }
        getpermissions()
    }, []);

    useEffect(() => {
        getvideos()
    }, [])

    useEffect(() => {
        if (typeof videoRef.current !== 'undefined') {
            videoRef.current.play();
        }
    }, [videopop]);

    function hadnlepopclose() {
        setvideopop('')
    }
    function handleVideo(props) {
        setvideopop(config.API_Video_URL +  'output/' +  props.data.ParentNo + '/' + props.data.CustomerNo + '/' + props.data.VideoName)
    };

    const VideoPopup = props =>
        <button type="button" onClick={() => handleVideo(props)} className='btnpopp' data-bs-toggle="modal" data-bs-target="#exampleModal">
            <IoIosPlayCircle />
        </button>;

    const [columnDefs] = useState([
        { field: '', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionCurrentPageOnly: true, width: 50, },
        { field: 'OrderNo', sortable: true, filter: true, resizable: true, },
        { field: 'createddate', headerName:'Created At',filter: true},
        { field: 'admin', sortable: true, filter: true, resizable: true, },
        { field: 'teammeber', sortable: true, filter: true, resizable: true,headerName:'Team Member' },
        { field: 'RecieverName',filter: true },
        { field: 'SenderName',filter: true},
        { field: 'TemplateName',filter: true },
        { field: 'VideoName' },
       
        { field: '', width: '80px', cellRenderer: VideoPopup,}
    ])

    const isRowSelectable = useMemo(() => {
        return (params) => {
            return !!params.data && params.data.year === 2012;
        };
    }, []);

    const onSelctionchanged = (event) => {
        var k = event.api.getSelectedRows()
        let videoNameData = []
        let SelectedViedeoIdData = []
        k.map((item) => {
            videoNameData.push(item.VideoPath)
            SelectedViedeoIdData.push(item.VideoId)
        })
        setSelectedrow(videoNameData)
        setSelectedViedeoId(SelectedViedeoIdData)
    }



    function getvideos() {

        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('StartDate', StartDate);
        newFromdata.append('endDate', endDate);
        setLoader(true)
        let url = config.API_BASE_URL+"video/gettrashedvideo.php"
        fetch(url, {
            method: 'POST',
            headers: {
                authorization: Authtoken(),
            },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            setVideodata(response.data)
            setLoader(false)
        });
    }

    function movetoVideos() {
        let token = Authtoken()
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('SelectedViedeoId', SelectedViedeoId);
        let url = config.API_BASE_URL+"video/movetovideos.php"
        setLoader(true)
        fetch(url, {
            method: 'POST',
            headers: {
                authorization: Authtoken(),
            },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            if (response.CODE == 200) {
                getvideos();
                setLoader(false)
            } else {

            }
        });
    }

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    function overlayout() {
        setIsOpen(false)
    }

    function setdaterange(data) {
        setState([data])
        let startday = data.startDate.getDate()
        let startmonth = data.startDate.getMonth() + 1
        let startyear = data.startDate.getFullYear()
        if (parseInt(startday) < 10) {
            startday = '0' + startday
        }
        if (parseInt(startmonth) < 10) {
            startmonth = '0' + startmonth
        }
        let startdate = startyear + '-' + startmonth + '-' + startday
        let endday = data.endDate.getDate()
        let endmonth = data.endDate.getMonth() + 1
        let endyear = data.endDate.getFullYear()
        if (parseInt(endday) < 10) {
            endday = '0' + endday
        }
        if (parseInt(endmonth) < 10) {
            endmonth = '0' + endmonth
        }
        let enddate = endyear + '-' + endmonth + '-' + endday
        setStartDate(startdate)
        setendDate(enddate)
    }

    function getvideosfilter() {
        let url = config.API_BASE_URL+"video/gettrashedvideo.php"
        var newFromdata = new FormData();
        newFromdata.append('token', Authtoken());
        newFromdata.append('StartDate', StartDate);
        newFromdata.append('endDate', endDate);
        fetch(url, {
            method: 'POST',
            headers: {
                authorization: Authtoken(),
            },
            body: newFromdata
        }).then(response => {
            return response.json();  // <---- this is important
        }).then(response => {
            setVideodata(response.data)
            setIsOpen(false)
        });

    }


    return (
        <>

            <div className='customcont'>

                <div onClick={hadnlepopclose} className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {(videopop) ?
                                    <video autoplay controls id="myVideo" className='videopopupsec' autoPlay>
                                        <source src={videopop} type="video/mp4" />
                                    </video>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {(loader) ? <p className='loadingtrash'><img src={loadingtrash} /></p> : ''}
                <div onClick={overlayout} className={`calenderoverlay ${isOpen ? 'active2' : ''}`}></div>
                <Header />
                {(PermissionLoader) ? 
                   <PermLoader />
                   :
                   <>
                       <div className='headingsec'>
                            <div className='headingsecinner'>
                                <h2>Trash Videos</h2>
                                {(Canview) ? 
                                    <div className='file'>
                                        <p onClick={handleToggle}>
                                            <BsCalendar2Date />
                                        </p>
                                        <button onClick={() => movetoVideos()}>Move to Videos</button>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                        {(Canview) ? 
                            <>
                                <div className={`calendertopcont ${isOpen ? 'active' : ''}`}>
                                    <button className='calendericontick' onClick={() => getvideosfilter()} >
                                        <BsCheck2 />
                                    </button>
                                    <DateRangePicker
                                        editableDateInputs={true}
                                        showMonthArrow={true}
                                        retainEndDateOnFirstSelection={false}
                                        fixedHeight={true}
                                        dragSelectionEnabled={true}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={state}
                                        direction="horizontal"
                                        rangeColors={['#dc0d5b', '#dc0d5b', '#dc0d5b']}
                                        onChange={item => setdaterange(item.selection)}
                                    />
                                </div>
                                <div className="ag-theme-alpine" style={{ width: '100%', paddingLeft: 10, paddingRight: 10, height: '76vh' }}>
                                    <AgGridReact
                                        rowData={Videodata}
                                        filter='text'
                                        enableColResize={true}
                                        animateRows={true}
                                        showToolPanel={true}
                                        enableSorting={true}
                                        pagination={true}
                                        onGridReady={onGridReady}
                                        paginationPageSize='10'
                                        rowSelection={'multiple'}
                                        columnDefs={columnDefs}
                                        onSelectionChanged={onSelctionchanged}
                                        overlayNoRowsTemplate={
                                            '<span className="ag-overlay-loading-center">No Data to Show</span>'
                                        }
                                    >                                  
                                    </AgGridReact>

                                </div>
                            </>
                            :
                            <p className = 'unautmessage'>Your are not authorized to view this page.</p>
                        }
                   </>
                }
                <Footer />
            </div>
        </>
    )
}
